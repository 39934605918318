import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';
import Swal from 'sweetalert2';

import { AlertMessageService } from '../alert-message/alert-message.service';
import { AppService } from '../app.service';

@Component({
    selector: 'app-test-drive-form',
    templateUrl: './test-drive-form.component.html',
    styleUrls: ['./test-drive-form.component.scss']
})
export class TestDriveFormComponent implements OnInit {
    form: FormGroup;
    formData = new FormData();
    isBlocked = false;
    isMoreInfoOpened = false;
    isFormSubmitted = false;
    // car = '';
    isPartnerSelectVisible = false;
    selectedCity: string;
    selectedPartner: any;
    filteredPartnersList = [];

    @Input() formKey: string;

    modelsToSelect = [
        {
            id: 'superb',
            name: 'Superb Combi Plug-in Hybrid ',
            iconUrl: './assets/images/form-icon/octavia_icon.png',
            formKey: 'superb-combi-plugin-hybrid',
            formName: 'oferta-superb-combi-plugin-hybrid-zamowiono-2025'
        },
        // {
        //     id: 'superb-limo',
        //     name: 'Superb Limo Plug-In Hybrid',
        //     iconUrl: './assets/images/form-icon/superb_icon.png',
        //     formKey: 'superb-limo-plugin-hybrid',
        //     formName: 'oferta-superb-limo-plugin-hybrid-zamowiono-2025'
        // },
        {
            id: 'kodiaq',
            name: 'Kodiaq Plug-in Hybrid',
            iconUrl: './assets/images/form-icon/superb_icon.png',
            formKey: 'kodiaq-plugin-hybrid',
            formName: 'oferta-kodiaq-zamowiono-2025'
        }];
    selectedModel;

    modelsPartnersList = [
        {
            city: 'Białystok',
            partners: [
                {
                    id: 235,
                    name: 'CRH Żagiel Auto Sp. z o.o.',
                    address: 'ul. Gen.St.Maczka 68'
                }
            ]
        },
        {
            city: 'Bielsko-Biała',
            partners: [
                {
                    id: 92,
                    name: 'Korczyk',
                    address: 'al. Gen. Władysława Andersa 586'
                }
            ]
        },
        {
            city: 'Bydgoszcz',
            partners: [
                {
                    id: 70,
                    name: 'Bieranowski',
                    address: 'ul. Jana Pawła II 127'
                },
                {
                    id: 243,
                    name: 'Bieranowski',
                    address: 'ul. Kamienna 78'
                }
            ]
        },
        {
            city: 'Ciechanów',
            partners: [
                {
                    id: 83,
                    name: 'Jarzyński Auto Serwis',
                    address: 'ul. Kasprzaka 5'
                }
            ]
        },
        {
            city: 'Częstochowa',
            partners: [
                {
                    id: 36,
                    name: 'Krotoski',
                    address: 'ul. Makuszyńskiego 74/76'
                }
            ]
        },
        {
            city: 'Dąbrowa Górnicza',
            partners: [
                {
                    id: 204,
                    name: `L'emir`,
                    address: 'ul. Jana Sobieskiego 10A'
                }
            ]
        },
        {
            city: 'Dobroń',
            partners: [
                {
                    id: 201,
                    name: 'Bednarek',
                    address: 'ul. Wrocławska 18'
                }
            ]
        },
        {
            city: 'Dzierżoniów',
            partners: [
                {
                    id: 101,
                    name: 'Centrum TMT',
                    address: 'ul. Świdnicka 151'
                }
            ]
        },
        {
            city: 'Elbląg',
            partners: [
                {
                    id: 224,
                    name: 'HADM Gramatowski',
                    address: 'ul. Warszawska 87'
                }
            ]
        },
        {
            city: 'Ełk',
            partners: [
                {
                    id: 42,
                    name: 'Fimot',
                    address: 'ul. Podmiejska 1'
                }
            ]
        },
        {
            city: 'Gdańsk',
            partners: [
                {
                    id: 150,
                    name: 'Plichta',
                    address: 'ul. Grunwaldzka 339'
                },
                {
                    id: 188,
                    name: 'Plichta',
                    address: 'ul. Lubowidzka 46'
                },
                {
                    id: 240,
                    name: 'Plichta',
                    address: 'ul. Marynarki Polskiej 94'
                }
            ]
        },
        {
            city: 'Gdynia',
            partners: [
                {
                    id: 88,
                    name: 'Plichta',
                    address: 'ul. Morska 80'
                }
            ]
        },
        {
            city: 'Gliwice',
            partners: [
                {
                    id: 141,
                    name: 'Lellek Gliwice',
                    address: 'ul. Portowa 2'
                }
            ]
        },
        {
            city: 'Kalisz',
            partners: [
                {
                    id: 34,
                    name: 'IGNASZAK',
                    address: 'al. Wojska Polskiego 5'
                }
            ]
        },
        {
            city: 'Katowice',
            partners: [
                {
                    id: 35,
                    name: 'Auto Śliwka',
                    address: 'ul. Kościuszki 94'
                },
                {
                    id: 179,
                    name: 'Ganinex',
                    address: 'al. Roździeńskiego 182'
                }
            ]
        },
        {
            city: 'Kielce',
            partners: [
                {
                    id: 157,
                    name: 'Autorud Kielce',
                    address: 'ul. Krakowska 283'
                }
            ]
        },
        {
            city: 'Koszalin',
            partners: [
                {
                    id: 189,
                    name: 'Cichy-Zasada Koszalin',
                    address: 'ul. Gnieźnieńska 43a'
                }
            ]
        },
        {
            city: 'Kraków',
            partners: [
                {
                    id: 196,
                    name: 'Inter Auto',
                    address: 'ul. Kocmyrzowska 1C'
                },
                {
                    id: 197,
                    name: 'Inter Auto',
                    address: 'ul. Zakopiańska 94'
                },
                {
                    id: 91,
                    name: 'Porsche Kraków Bronowice',
                    address: 'ul. Josepha Conrada 10'
                }
            ]
        },
        {
            city: 'Leszno',
            partners: [
                {
                    id: 161,
                    name: 'Ciesiółka Auto Group',
                    address: 'al. Konstytucji 3 Maja 13'
                }
            ]
        },
        {
            city: 'Lubicz Dolny',
            partners: [
                {
                    id: 98,
                    name: 'Plichta',
                    address: 'ul. Toruńska 85'
                }
            ]
        },
        {
            city: 'Lubin',
            partners: [
                {
                    id: 219,
                    name: 'RE-WO Volumis Motors',
                    address: 'ul. Hutnicza 2 A'
                }
            ]
        },
        {
            city: 'Lublin',
            partners: [
                {
                    id: 97,
                    name: 'Żagiel Auto',
                    address: 'ul. Roztocze 4'
                }
            ]
        },
        {
            city: 'Łódź',
            partners: [
                {
                    id: 59,
                    name: 'Zimny Auto',
                    address: 'ul. Rzgowska 142'
                },
                {
                    id: 200,
                    name: 'Bednarek',
                    address: 'ul. Szczecińska 38A'
                }
            ]
        },
        {
            city: 'Mielec',
            partners: [
                {
                    id: 76,
                    name: 'MKT Serwis',
                    address: 'Wola Mielecka 592'
                }
            ]
        },
        {
            city: 'Mińsk Mazowiecki-Stojadła',
            partners: [
                {
                    id: 122,
                    name: 'Carsed',
                    address: 'ul. Warszawska 84'
                }
            ]
        },
        {
            city: 'Nowy Sącz',
            partners: [
                {
                    id: 69,
                    name: 'Autoremo',
                    address: 'ul. Wiśniowieckiego 123'
                }
            ]
        },
        {
            city: 'Nowy Targ',
            partners: [
                {
                    id: 146,
                    name: 'Autoremo',
                    address: 'ul. Szaflarska 170'
                }
            ]
        },
        {
            city: 'Olsztyn',
            partners: [
                {
                    id: 11,
                    name: 'Fiedorowicz',
                    address: 'ul. Sielska 42'
                },
                {
                    id: 11,
                    name: 'Fiedorowicz',
                    address: 'ul. Sielska 42'
                }
            ]
        },
        {
            city: 'Opacz Kolonia',
            partners: [
                {
                    id: 84,
                    name: 'Cichy - Zasada Al. Jerozolimskie',
                    address: 'al. Jerozolimskie 249b'
                }
            ]
        },
        {
            city: 'Opole',
            partners: [
                {
                    id: 31,
                    name: 'Lellek Opole',
                    address: 'ul. Opolska 2c'
                }
            ]
        },
        {
            city: 'Piotrków Trybunalski',
            partners: [
                {
                    id: 39,
                    name: 'Zimny Auto',
                    address: 'ul. Wojska Polskiego 102'
                }
            ]
        },
        {
            city: 'Piła',
            partners: [
                {
                    id: 60,
                    name: 'Auto-Park',
                    address: 'ul. Siemiradzkiego 23'
                }
            ]
        },
        {
            city: 'Pisz',
            partners: [
                {
                    id: 126,
                    name: 'Fimot',
                    address: 'ul. Warszawska 47A'
                }
            ]
        },
        {
            city: 'Płock',
            partners: [
                {
                    id: 194,
                    name: 'Auto Forum 2',
                    address: 'ul. Skrzetuskiego 11'
                }
            ]
        },
        {
            city: 'Poznań',
            partners: [
                {
                    id: 27,
                    name: 'Porsche Obornicka',
                    address: 'ul. Obornicka 249'
                },
                {
                    id: 149,
                    name: 'Cichy-Zasada Poznań',
                    address: 'ul. Unii Lubelskiej 5'
                },
                {
                    id: 160,
                    name: 'Skoda Centrum Poznań',
                    address: 'ul. Jadwigi Wajsówny  7-9'
                }
            ]
        },
        {
            city: 'Rybnik',
            partners: [
                {
                    id: 206,
                    name: 'Auto-Gazda',
                    address: 'ul. Żorska 11A'
                },
                {
                    id: 107,
                    name: 'Auto Salon Margo Szczotok',
                    address: 'ul. Wodzisławska 201'
                }
            ]
        },
        {
            city: 'Rzeszów-Krasne',
            partners: [
                {
                    id: 211,
                    name: 'Inter Auto',
                    address: 'Krasne 7'
                }
            ]
        },
        {
            city: 'Rzeszów-Świlcza',
            partners: [
                {
                    id: 57,
                    name: 'Rex Auto',
                    address: 'Świlcza 147 Z'
                }
            ]
        },
        {
            city: 'Siedlce',
            partners: [
                {
                    id: 47,
                    name: 'Carsed',
                    address: 'ul. Brzeska 181'
                }
            ]
        },
        {
            city: 'Słupsk',
            partners: [
                {
                    id: 193,
                    name: 'Cichy-Zasada Słupsk',
                    address: 'ul. Sezamkowa 7'
                }
            ]
        },
        {
            city: 'Sosnowiec',
            partners: [
                {
                    id: 137,
                    name: 'Auto Śliwka',
                    address: 'ul. 3 Maja 60'
                }
            ]
        },
        {
            city: 'Starogard Gdański',
            partners: [
                {
                    id: 90,
                    name: 'HADM Gramatowski',
                    address: 'Rokocin 4G'
                }
            ]
        },
        {
            city: 'Szczecin',
            partners: [
                {
                    id: 192,
                    name: 'Cichy-Zasada Szczecin',
                    address: 'ul. Andrzeja Struga 1A'
                },
                {
                    id: 242,
                    name: 'Skoda City Store Szczecin',
                    address: 'pl. Brama Portowa 1'
                }
            ]
        },
        {
            city: 'Świdnik',
            partners: [
                {
                    id: 87,
                    name: 'G&G Auto',
                    address: 'ul. Piasecka 20 A'
                }
            ]
        },
        {
            city: 'Świętochłowice',
            partners: [
                {
                    id: 178,
                    name: 'Porsche Katowice',
                    address: 'ul. Drogowa Trasa Średnicowa 2'
                }
            ]
        },
        {
            city: 'Tarnowskie Góry',
            partners: [
                {
                    id: 54,
                    name: 'Lellek Tarnowskie Góry',
                    address: 'ul. Leśna 2'
                }
            ]
        },
        {
            city: 'Tarnów',
            partners: [
                {
                    id: 154,
                    name: 'Auto Bączek',
                    address: 'ul. Gumniska 36a'
                }
            ]
        },
        {
            city: 'Tychy',
            partners: [
                {
                    id: 212,
                    name: 'Porsche Tychy',
                    address: 'ul. Beskidzka 105'
                }
            ]
        },
        {
            city: 'Warszawa',
            partners: [
                {
                    id: 17,
                    name: 'Cichy - Zasada Modlińska',
                    address: 'ul. Modlińska 224'
                },
                {
                    id: 58,
                    name: 'Porsche Połczyńska',
                    address: 'ul. Połczyńska 125'
                },
                {
                    id: 230,
                    name: 'Štore Cichy - Zasada Grochowska',
                    address: 'ul. Grochowska 306/308'
                },
                {
                    id: 234,
                    name: 'Cichy - Zasada Ursynów',
                    address: 'ul. Gruchacza 39'
                },
                {
                    id: 241,
                    name: 'Cichy-Zasada',
                    address: 'ul. Heliotropów 23'
                }
            ]
        },
        {
            city: 'Warszawa-Falenty',
            partners: [
                {
                    id: 205,
                    name: 'Bohemia Motors',
                    address: 'al. Krakowska 5/A'
                }
            ]
        },
        {
            city: 'Wałbrzych',
            partners: [
                {
                    id: 159,
                    name: 'Auto Sudety Sp. z o.o.',
                    address: 'ul. Wrocławska 159'
                }
            ]
        },
        {
            city: 'Wejherowo',
            partners: [
                {
                    id: 66,
                    name: 'Plichta',
                    address: 'ul. Gdańska 13C'
                }
            ]
        },
        {
            city: 'Wieliczka',
            partners: [
                {
                    id: 52,
                    name: 'Auto Group Luzar',
                    address: 'ul. Krakowska 33'
                }
            ]
        },
        {
            city: 'Włocławek',
            partners: [
                {
                    id: 22,
                    name: 'Wątarski',
                    address: 'ul. Toruńska 169'
                }
            ]
        },
        {
            city: 'Wrocław',
            partners: [
                {
                    id: 173,
                    name: 'Centrum TMT',
                    address: 'ul. Horbaczewskiego 75'
                },
                {
                    id: 226,
                    name: 'Motorpol Wrocław',
                    address: 'al. Karkonoska 81'
                },
                {
                    id: 218,
                    name: 'Centrum Wrocław',
                    address: 'al. Aleksandra Brucknera 40-46'
                },
                {
                    id: 64,
                    name: 'Gall-ICM Wrocław',
                    address: 'ul. Armii Krajowej 46'
                }
            ]
        },
        {
            city: 'Zabrze',
            partners: [
                {
                    id: 144,
                    name: 'Auto Śliwka',
                    address: 'ul. Plutonowego Szkubacza 4'
                }
            ]
        },
        {
            city: 'Zgorzelec',
            partners: [
                {
                    id: 158,
                    name: 'Ultima',
                    address: 'ul. Lubańska 13A'
                }
            ]
        },
        {
            city: 'Zielona Góra',
            partners: [
                {
                    id: 216,
                    name: 'KIM SP. Z O.O.',
                    address: 'ul. Kostrzyńska  6'
                }
            ]
        },
    ];

    constructor(
        private appService: AppService,
        private alertService: AlertMessageService,
        private elem: ElementRef,
        private fb: FormBuilder,
        private http: HttpClient,
        private router: Router,
        private scrollService: ScrollToService
    ) { }

    @Input() isMobileDevice: boolean;

    ngOnInit() {
        this.appService.isHomePage = true;
        this.initForm();
        this.appendFieldsToFormData();
        this.setCarModel(this.modelsToSelect[0]);
        this.isPartnerSelectVisible = false;
    }

    onInputChange(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.value });
        this.checkApprovalsValues();
    }

    changeValue(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.checked });
        this.checkApprovalsValues();
    }

    checkApprovalsValues() {
        if (
            (this.form.value['gdpr-answer-marketing-messages-importer'] &&
                !(
                    this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls']
                )) ||
            (!this.form.value['gdpr-answer-marketing-messages-importer'] &&
                (this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls'])) ||
            (this.form.value['gdpr-answer-email'] && !this.form.value.email)
        ) {
            this.isBlocked = true;
        } else {
            this.isBlocked = false;
        }
    }

    closePopup() {
        this.router.navigateByUrl('');
    }

    initForm() {
        this.form = this.fb.group(
            {
                name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ ]{2,20}$')]],
                phoneNo: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{9}$')]],
                email: ['', [Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')]],
                'gdpr-answer-marketing-messages-importer': false,
                'gdpr-answer-email': false,
                'gdpr-answer-electronic-messages': false,
                'gdpr-answer-voice-calls': false
            },
            { updateOn: 'blur' }
        );
    }

    mapApprovalsValues() {
        this.form.value['gdpr-answer-marketing-messages-importer'] = this.form.value['gdpr-answer-marketing-messages-importer'] ? 1 : 0;
        this.form.value['gdpr-answer-email'] = this.form.value['gdpr-answer-email'] ? 1 : 0;
        this.form.value['gdpr-answer-electronic-messages'] = this.form.value['gdpr-answer-electronic-messages'] ? 1 : 0;
        this.form.value['gdpr-answer-voice-calls'] = this.form.value['gdpr-answer-voice-calls'] ? 1 : 0;
    }



    sendForm() {
        // this.appService.trackSyneriseEvent('submit', 'jazda.probna');

        // if(this.formKey === 'test-drive-octavia') {
        //     this.appService.trackSyneriseEvent("LPelektrykiJazdaProbnaOctavia.send", "LPelektrykiJazdaProbnaOctaviaSend");
        // } else {
        //     this.appService.trackSyneriseEvent("LPelektrykiJazdaProbnaSuperb.send", "LPelektrykiJazdaProbnaSuperbSend");
        // }

        let apiUrl = '';
        const formName = '';
        let formNameSynerise = '';
        let formNameDataLayer = '';
        let model = '';
        let cityAndAddress = '';
        this.isFormSubmitted = true;
        this.checkApprovalsValues();
        cityAndAddress = `${this.form.value.city} ${this.form.value.address}`;
        if (this.form.valid && !this.isBlocked) {
            this.mapApprovalsValues();
            this.setValuesOfFormData();

            apiUrl = 'https://fbods2.kampanie.skoda.pl/web/handler/202409_emobility_JP?key=er23riru8wefewfiewfief34mi43egergerge';
            model = this.selectedModel.id;
            this.formData.set('cityAddress', cityAndAddress);
            cityAndAddress = `${this.form.value.city} ${this.form.value.address}`;
            this.formData.set('cityAddress', cityAndAddress);
            formNameSynerise = `${model},jazda.probna,lp`;
            formNameDataLayer = 'jazda-probna-zamowiono-202102_hybrydy_LP_jp';

            this.http
                .post<{ externalId: string; status: string }>(
                    apiUrl,
                    this.formData
                )
                .subscribe(
                    data => {
                        if (data.status === 'error') {
                            Swal.fire({
                                title: 'Błąd',
                                text: 'Wystąpił błąd podczas wysyłania danych.',
                                icon: 'error',
                            });
                            return false;
                        } else {
                            this.onSubmitSuccessForm(data.externalId, formNameDataLayer, formNameSynerise, model);
                            this.setAlertMessageType();
                            this.resetForm();
                            this.isBlocked = false;
                            this.isFormSubmitted = false;
                        }
                    },
                    error => {
                        Swal.fire({
                            title: 'Błąd',
                            text: 'Wystąpił błąd podczas wysyłania danych.',
                            icon: 'error',
                        });
                    }
                );
        } else {
            const invalidFields = this.elem.nativeElement.querySelectorAll('.ng-invalid');
            this.scrollService.scrollTo(invalidFields[1], 600, -70);
        }
    }

    setCarModel(car) {
        this.selectedModel = car;
        this.isPartnerSelectVisible = true;
        this.selectedCity = '';
        this.selectedPartner = null;
        this.filteredPartnersList = [];

    }

    onCitySelect(city: string) {
        this.selectedCity = city;
        this.selectedPartner = null;
        const cityPartners = this.modelsPartnersList.find(item => item.city === city);
        this.filteredPartnersList = cityPartners ? cityPartners.partners : [];
    }

    onPartnerSelect(partner: any) {
        this.selectedPartner = partner;
    }

    private setAlertMessageType() {
        if (this.form.value['gdpr-answer-email'] && !(this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('email');
        } else if (!this.form.value['gdpr-answer-email'] && (this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('phone');
        } else if (this.form.value['gdpr-answer-email'] && (this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('all');
        } else {
            this.alertService.setAlertMessage('default');
        }
    }

    private appendFieldsToFormData() {
        this.formData.append('formType', '');
        this.formData.append('carModel', '');
        this.formData.append('cityAddress', `${this.form.value.city} ${this.form.value.address}`);
        this.formData.append('name', '');
        this.formData.append('phoneNo', '');
        this.formData.append('email', '');
        this.formData.append('gdpr-answer-marketing-messages-importer', '');
        this.formData.append('gdpr-answer-email', '');
        this.formData.append('gdpr-answer-electronic-messages', '');
        this.formData.append('gdpr-answer-voice-calls', '');
    }

    private setValuesOfFormData() {
        this.formData.set('formType', this.selectedModel.name + ' ' + 'test-drive');
        this.formData.set('carModel', this.selectedModel.formKey);
        this.formData.set('cityAddress', `${this.form.value.city} ${this.form.value.address}`);
        this.formData.set('name', this.form.value.name);
        this.formData.set('phoneNo', this.form.value.phoneNo);
        this.formData.set('email', this.form.value.email);
        this.formData.set('gdpr-answer-marketing-messages-importer', this.form.value['gdpr-answer-marketing-messages-importer']);
        this.formData.set('gdpr-answer-email', this.form.value['gdpr-answer-email']);
        this.formData.set('gdpr-answer-electronic-messages', this.form.value['gdpr-answer-electronic-messages']);
        this.formData.set('gdpr-answer-voice-calls', this.form.value['gdpr-answer-voice-calls']);
    }

    onSubmitSuccessForm(orderId, formNameDataLayer, formNameSynerise, modelName) {
        const dataLayer = window['dataLayer'];
        dataLayer.push({
            event: 'formularze',
            'wypelniony-formularz': formNameDataLayer,
            orderid: orderId,
            model: modelName
        });

        dataLayer.push({
            event: 'ga_event',
            eventCategory: 'formularze',
            eventAction: 'wypełniony formularz',
            eventLabel: 'jazda-probna-zamowiono-202108-Safety'
        });

        if (window['SyneriseTC']) {
            window['SyneriseTC'].sendFormData(
                formNameSynerise,
                {
                    firstname: this.form.controls.name.value,
                    phone: this.form.controls.phoneNo.value,
                    email: this.form.controls.email.value,
                    // newsletterAgreement: this.form.controls['gdpr-answer-email'].value ? 'enabled' : 'disabled',
                    // receive_smses: this.form.controls['gdpr-answer-electronic-messages'].value ? 'true' : 'false',
                    // phonecall: this.form.controls['gdpr-answer-voice-calls'].value ? 'yes' : 'no',
                    orderID: orderId
                }
            );
        }
    }

    private resetForm() {
        this.form.reset();
        this.form.patchValue({
            name: '',
            phoneNo: '',
            email: '',
            'gdpr-answer-marketing-messages-importer': false,
            'gdpr-answer-email': false,
            'gdpr-answer-electronic-messages': false,
            'gdpr-answer-voice-calls': false
        });
    }
}
