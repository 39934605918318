import { Component, OnInit, AfterContentInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { element } from 'protractor';
import { AppService } from '../app.service';

declare var $: any;

@Component({
    selector: 'app-plug-in',
    templateUrl: './plug-in.component.html',
    styleUrls: ['./plug-in.component.scss']
})
export class PlugInComponent implements OnInit, AfterViewInit {

    constructor(
        private appService: AppService
    ) { }

    @Input() isMobileDevice: boolean;


    plugInHeader = 'Silnik elektryczny';
    plugInParagraph = 'Cicha i dynamiczna jednostka o mocy 85 kW (116 KM) już od startu oferuje pełny moment obrotowy. Jej maksymalny zasięg, który wynosi prawie 130 km w trybie elektrycznym w przypadku Škody Superb Liftback Plug-In Hybrid oraz Škody Superb Combi Plug-in Hybrid, umożliwia dłuższe podróże bez spalania choćby litra paliwa.';

    plugInTitleConfig = {
        focusOnSelect: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        speed: 1500,
        infinity: true,
        asNavFor: '.plug-in-carusel',
    };

    plugInConfig = {
        variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1500,
        asNavFor: '.plug-in-carusel-title',
        focusOnSelect: true
    };


    @ViewChild('firstFocus', { static: true }) item: ElementRef;
    @ViewChild('slickModal', { static: false }) carousel: SlickCarouselComponent;

    mobilePlugIn = [
        {
            title: 'Gniazdo ładowania',
            content: 'Każda hybryda ładuje się w trakcie jazdy dzięki rekuperacji. Jednak tylko hybryda typu plug-in oferuje możliwość ładowania z zewnętrznego źródła energii, w tym także zwykłego gniazdka, co pozwala na zastosowanie mocniejszych i wydajniejszych silników elektrycznych.'
        },
        {
            title: 'Silnik spalinowy',
            content: 'Jednostka 1.5 TSI należy do najnowszej generacji EA 211 evo2 i we współpracy z silnikiem elektrycznym oferuje łączną moc 150 kW(204 KM).'
        },
        {
            title: this.plugInHeader,
            content: this.plugInParagraph
        },
        {
            title: 'Akumulator litowo-jonowy',
            content: 'Oferuje pojemność 25,7 kWh umożliwiającą długą jazdę tylko w trybie elektrycznym. Ładowany prądem przemiennym o mocy 11 kW lub prądem stałym o mocy 40 kW. Na szybkie ładowanie od 10% do 80% wystarczy ledwie 25 minut.'
        },
        {
            title: 'Zbiornik paliwa',
            content: '45-litrowy zbiornik daje Ci pewność w czasie najdłuższych podróży. W trybie hybrydowym spalanie benzyny to   nawet 0,4 l/100 km.'
        }

    ];

    checkClickItem() {
        const items = document.querySelectorAll('.cta-item');

        for (const el of items as any) {
            el.addEventListener('click', function () {

                this.classList.add('bg');

                if (this.classList.contains('bg')) {
                    const clickedItem = this;
                    const activeItems = document.querySelectorAll('.cta-item.bg');
                    for (const bgItem of activeItems as any) {
                        if (bgItem !== clickedItem) {
                            bgItem.classList.remove('bg');
                        }
                    }
                }
            });
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.checkClickItem();
        this.item.nativeElement.classList.add('bg');
    }

    contentPlugIn(item) {
        if (item === 'charging') {
            this.plugInHeader = 'Gniazdo ładowania';
            this.plugInParagraph = 'Każda hybryda ładuje się w trakcie jazdy dzięki rekuperacji. Jednak tylko hybryda typu plug-in oferuje możliwość ładowania z zewnętrznego źródła energii, w tym także zwykłego gniazdka, co pozwala na zastosowanie mocniejszych i wydajniejszych silników elektrycznych.';
        } else if (item === 'engine') {
            this.plugInHeader = 'Silnik spalinowy';
            this.plugInParagraph = 'Jednostka 1.5 TSI należy do najnowszej generacji EA 211 evo2 i we współpracy z silnikiem elektrycznym oferuje łączną moc 150 kW (204 KM).';
        } else if (item === 'electric') {
            this.plugInHeader = 'Silnik elektryczny';
            this.plugInParagraph = 'Cicha i dynamiczna jednostka o mocy 85 kW (116 KM) już od startu oferuje pełny moment obrotowy. Jej maksymalny zasięg, który wynosi prawie 130 km w trybie elektrycznym w przypadku Škody Superb Combi Plug in Hybrid, umożliwia dłuższe podróże bez spalania choćby litra paliwa.';
        } else if (item === 'battery') {
            this.plugInHeader = 'Akumulator litowo-jonowy';
            this.plugInParagraph = 'Oferuje pojemność 25,7 kWh umożliwiającą długą jazdę tylko w trybie elektrycznym. Ładowany prądem przemiennym o mocy 11 kW lub prądem stałym o mocy 40 kW. Na szybkie ładowanie od 10% do 80% wystarczy ledwie 25 minut.';
        } else if (item === 'fuel') {
            this.plugInHeader = 'Zbiornik paliwa';
            this.plugInParagraph = '45-litrowy zbiornik daje Ci pewność w czasie najdłuższych podróży. W trybie hybrydowym spalanie benzyny to nawet 0,4 l/100 km.';
        }
    }

    goToSlide(id) {
        $('.carousel').slick('slickGoTo', id);
    }

    checkIndex(event) {
        const index = event.target.parentElement.parentElement.getAttribute('data-slick-index');

        if (index === '0') {
            const element0: HTMLElement = document.querySelector('.cta-item.item-1') as HTMLElement;
            element0.click();
        }

        if (index === '1') {
            const element1: HTMLElement = document.querySelector('.cta-item.item-2') as HTMLElement;
            element1.click();
        }

        if (index === '2') {
            const element2: HTMLElement = document.querySelector('.cta-item.item-3') as HTMLElement;
            element2.click();
        }

        if (index === '3') {
            const element3: HTMLElement = document.querySelector('.cta-item.item-4') as HTMLElement;
            element3.click();
        }

        if (index === '4') {
            const element4: HTMLElement = document.querySelector('.cta-item.item-5') as HTMLElement;
            element4.click();
        }

        if (index === '5') {
            const element5: HTMLElement = document.querySelector('.cta-item.item-1') as HTMLElement;
            element5.click();
        }


        if (index === '6') {
            const element6: HTMLElement = document.querySelector('.cta-item.item-2') as HTMLElement;
            element6.click();
        }

        if (index === '7') {
            const element7: HTMLElement = document.querySelector('.cta-item.item-3') as HTMLElement;
            element7.click();
        }

        if (index === '8') {
            const element8: HTMLElement = document.querySelector('.cta-item.item-4') as HTMLElement;
            element8.click();
        }

        if (index === '9') {
            const element9: HTMLElement = document.querySelector('.cta-item.item-5') as HTMLElement;
            element9.click();
        }

    }

    trackGAEvent(eventName: string) {
        this.appService.trackGAEventClick(eventName);
    }
}
