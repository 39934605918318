// import { TestDriveFormComponent } from './test-drive-form/test-drive-form.component';
import { OfferFormComponent} from './offer-form/offer-form.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { FormComponent } from './form/form.component';

const routes: Routes = [
    { path: '', component: HomePageComponent },
    {path: 'oferta', component: OfferFormComponent},
    { path: 'newsletter', component: FormComponent },
    { path: '', redirectTo: '', pathMatch: 'full' },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: false, scrollPositionRestoration: 'disabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
