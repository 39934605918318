import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ScrollToService } from 'ng2-scroll-to-el';

import { AlertMessageService } from '../alert-message/alert-message.service';
import { AppService } from '../app.service';

@Component({
    selector: 'app-offer-form',
    templateUrl: './offer-form.component.html',
    styleUrls: ['./offer-form.component.scss']
})
export class OfferFormComponent implements OnInit {
    form: FormGroup;
    formData = new FormData();
    isBlocked = false;
    isFormSubmitted = false;
    isSendSuccessful = false;
    isSendError = false;
    isMoreInfoOpened = false;
    isLoading = false;

    @Input() formKey: string;

    modelsToSelect = [
        {
            id: 'superb',
            name: 'Superb Combi Plug-in Hybrid ',
            iconUrl: './assets/images/form-icon/octavia_icon.png',
            formKey: 'superb-combi-plugin-hybrid',
            formName: 'oferta-superb-combi-plugin-hybrid-zamowiono-2025'
        },
        // {
        //     id: 'superb-limo',
        //     name: 'Superb Limo Plug-In Hybrid',
        //     iconUrl: './assets/images/form-icon/superb_icon.png',
        //     formKey: 'superb-limo-plugin-hybrid',
        //     formName: 'oferta-superb-limo-plugin-hybrid-zamowiono-2025'
        // },
        {
            id: 'kodiaq',
            name: 'Kodiaq Plug-in Hybrid',
            iconUrl: './assets/images/form-icon/superb_icon.png',
            formKey: 'kodiaq-plugin-hybrid',
            formName: 'oferta-kodiaq-zamowiono-2025'
        }];
    selectedModel;

    @Input() isMobileDevice: boolean;

    constructor(
        private alertService: AlertMessageService,
        private fb: FormBuilder,
        private elem: ElementRef,
        private scrollService: ScrollToService,
        private http: HttpClient,
        private router: Router,
        private appService: AppService
    ) { }

    ngOnInit() {
        this.appService.isHomePage = false;
        window.scrollTo(0, 0);
        this.initForm();
        this.appendFieldsToFormData();
        this.setCarModel(this.modelsToSelect[0]);
    }

    initForm() {
        this.form = this.fb.group(
            {
                name: ['', [Validators.required, Validators.minLength(2), Validators.pattern('^[A-Z-a-ząóęćłśńżźĄÓĘĆŁŚŃŻŹ ]{2,20}$')]],
                phoneNo: ['', [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{9}$')]],
                email: ['', [Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,4}$')]],
                'gdpr-answer-marketing-messages-importer': false,
                'gdpr-answer-email': false,
                'gdpr-answer-electronic-messages': false,
                'gdpr-answer-voice-calls': false
            },
            { updateOn: 'blur' }
        );
    }

    mapApprovalsValues() {
        this.form.value['gdpr-answer-marketing-messages-importer'] = this.form.value['gdpr-answer-marketing-messages-importer'] ? 1 : 0;
        this.form.value['gdpr-answer-email'] = this.form.value['gdpr-answer-email'] ? 1 : 0;
        this.form.value['gdpr-answer-electronic-messages'] = this.form.value['gdpr-answer-electronic-messages'] ? 1 : 0;
        this.form.value['gdpr-answer-voice-calls'] = this.form.value['gdpr-answer-voice-calls'] ? 1 : 0;
    }

    onInputChange(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.value });
        this.checkApprovalsValues();
    }

    changeValue(e, controlName) {
        this.form.patchValue({ [controlName]: e.target.checked });
        this.checkApprovalsValues();
    }

    checkApprovalsValues() {
        if (
            (this.form.value['gdpr-answer-marketing-messages-importer'] &&
                !(this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls']
                )) ||
            (!this.form.value['gdpr-answer-marketing-messages-importer'] &&
                (this.form.value['gdpr-answer-email'] ||
                    this.form.value['gdpr-answer-electronic-messages'] ||
                    this.form.value['gdpr-answer-voice-calls'])) ||
            (this.form.value['gdpr-answer-email'] && !this.form.value.email)
        ) {
            this.isBlocked = true;
        } else {
            this.isBlocked = false;
        }
    }

    closePopup() {
        this.router.navigate(['/']);
        window.scrollTo(0, 0);
    }

    sendForm() {
        let apiUrl = '';
        let formNameSynerise = '';
        let formNameDataLayer = '';
        let model = '';
        this.isFormSubmitted = true;
        this.checkApprovalsValues();

        if (this.form.valid && !this.isBlocked) {
            this.mapApprovalsValues();
            this.setValuesOfFormData();

            apiUrl = 'https://fbods2.kampanie.skoda.pl/web/handler/202409_emobility_OF?key=er23riru8wefewfiewfief34mi43egergerge';
            model = this.selectedModel.id;
            formNameSynerise = `${model},oferta,lp`;
            formNameDataLayer = 'oferta-zamowiono-202102_hybrydy_LP_of';

            this.isLoading = true;
            this.http.post<{ externalId: string; status: string }>(apiUrl, this.formData).subscribe(
                data => {
                    if (data.status === 'error') {
                        this.isLoading = false;
                        this.isSendError = true;
                        return false;
                    } else {
                        this.setAlertMessageType();
                        this.isLoading = false;
                        this.isSendSuccessful = true;
                        this.onSubmitSuccessForm(data.externalId, formNameDataLayer, formNameSynerise, model);
                        this.resetForm();
                        this.isBlocked = true;
                        this.isFormSubmitted = false;
                        return true;
                    }
                },
                error => {
                    this.isLoading = false;
                    this.isSendError = true;
                }
            );

        } else {
            const invalidFields = this.elem.nativeElement.querySelectorAll('.ng-invalid');
            this.scrollService.scrollTo(invalidFields[1], 600, -170);
        }
    }

    setCarModel(car) {
        this.selectedModel = car;
    }

    private setAlertMessageType() {
        if (this.form.value['gdpr-answer-email'] && !(this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('email');
        } else if (!this.form.value['gdpr-answer-email'] && (this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('phone');
        } else if (this.form.value['gdpr-answer-email'] && (this.form.value['gdpr-answer-electronic-messages'] || this.form.value['gdpr-answer-voice-calls'])) {
            this.alertService.setAlertMessage('all');
        } else {
            this.alertService.setAlertMessage('default');
        }
    }

    private appendFieldsToFormData() {
        this.formData.append('formType', '');
        this.formData.append('carModel', '');
        this.formData.append('name', '');
        this.formData.append('phoneNo', '');
        this.formData.append('email', '');
        this.formData.append('gdpr-answer-marketing-messages-importer', '');
        this.formData.append('gdpr-answer-email', '');
        this.formData.append('gdpr-answer-electronic-messages', '');
        this.formData.append('gdpr-answer-voice-calls', '');
    }

    private setValuesOfFormData() {
        this.formData.set('formType', this.selectedModel.name + ' ' + this.formKey);
        this.formData.set('carModel', this.selectedModel.formKey);
        this.formData.set('name', this.form.value.name);
        this.formData.set('phoneNo', this.form.value.phoneNo);
        this.formData.set('email', this.form.value.email);
        this.formData.set('gdpr-answer-marketing-messages-importer', this.form.value['gdpr-answer-marketing-messages-importer']);
        this.formData.set('gdpr-answer-email', this.form.value['gdpr-answer-email']);
        this.formData.set('gdpr-answer-electronic-messages', this.form.value['gdpr-answer-electronic-messages']);
        this.formData.set('gdpr-answer-voice-calls', this.form.value['gdpr-answer-voice-calls']);
    }


    onSubmitSuccessForm(orderId, formNameDataLayer, formNameSynerise, modelName) {
        const dataLayer = window['dataLayer'];
        dataLayer.push({
            event: 'formularze',
            'wypelniony-formularz': formNameDataLayer,
            orderid: orderId,
            model: modelName
        });

        dataLayer.push({
            event: 'ga_event',
            eventCategory: 'formularze',
            eventAction: 'wypełniony formularz',
            eventLabel: 'oferta-zamowiono-202108-hybrydy'
        });

        if (window['SyneriseTC'] !== undefined) {
            window['SyneriseTC'].sendFormData(formNameSynerise, {
                firstname: this.form.value.name,
                phone: this.form.value.phoneNo,
                email: this.form.value.email,
                // newsletterAgreement: this.form.value['gdpr-answer-email'] ? 'enabled' : 'disabled',
                // receive_smses: this.form.value['gdpr-answer-electronic-messages'] ? 'true' : 'false',
                // phonecall: this.form.value['gdpr-answer-voice-calls'] ? 'yes' : 'no',
                orderID: orderId
            });
        }
    }

    private resetForm() {
        this.form.reset();
        this.form.patchValue({
            name: '',
            phoneNo: '',
            email: '',
            'gdpr-answer-marketing-messages-importer': false,
            'gdpr-answer-email': false,
            'gdpr-answer-electronic-messages': false,
            'gdpr-answer-voice-calls': false
        });
    }
}
