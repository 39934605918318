import {
    Component,
    OnInit,
    Renderer2,
    ViewChild,
    ElementRef
} from '@angular/core';
import { AppService } from '../app.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    @ViewChild('toggler', { static: false }) toggler: ElementRef;

    constructor(
        private renderer: Renderer2,
        private appService: AppService,
        private router: Router
    ) {}

    ngOnInit() {}

    hideMobileNav() {
        this.renderer.removeClass(this.toggler.nativeElement, 'show');
        this.renderer.addClass(this.toggler.nativeElement, 'hide');
    }

    navigateTo(sectionId) {
        if (!this.appService.isHomePage) {
            this.appService.currentSectionName = sectionId;
            this.router.navigate(['/']);
        } else {
            this.appService.setNavSectionRef(sectionId);
        }
        this.hideMobileNav();
    }

    trackSyneriseEvent(
        eventType: string,
        eventName: string,
        modelName?: string
      ) {
        this.appService.trackSyneriseEvent(eventType, eventName, modelName);
    }

    pushDataLayer(eventName, eventLabel, eventCategory) {
        this.appService.trackGAEventClick(eventName, eventLabel, eventCategory);
    }
}
